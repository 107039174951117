module.exports = {
	name: 'host',
	exposes: {
		'./app': './src/App/App.jsx',
		'./store': './src/StoreProvider.jsx',
		'./components': './src/components/index.js',
		'./employeeInformation': './src/components/TableV2/ChildComponents/EmployeeInformation.js',
		'./assets': './src/assets',
		'./modules': './src/modules/index.js',
		'./api': './src/common/api.js',
		'./hooks': './src/common/hooks/index.js',
		'./utils': './src/common/utils/index.js',
		'./constants': './src/common/constants/index.js',
		'./cookies': './src/common/utils/cookie.js',
		'./language': './src/lang/IntlWrapper.js',
		'./fetch': './src/common/fetch.js',
		'./contexts': './src/contexts/index.js',
		'./connect': './src/components/Connect/index.js',
		'./Stripe': './src/common/payment/Stripe/exports.js',
		'./CustomDashboard': './src/components/CustomDashboard/CustomDashboard.jsx',
	},
};
